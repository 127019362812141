"use client";
import {
  CssBaseline,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material";
import { ContentWrapper } from "./wrapper";
import ShadeGenerator from "shade-generator";
import * as defaultTheme from "steps/shared/theme";
import hexRgb from "steps/shared/utils/hex-rgb";

export const ThemeWrapper = ({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme?: any;
}) => {
  const layoutBgColorArray: any = ShadeGenerator.hue(
    theme?.layout?.["background_color"] || "#ffffff",
  ).shadesMap("hex");
  const layoutTextColorArray: any = ShadeGenerator.hue(
    theme?.layout?.["text_color"] || "#1b1e3d",
  ).shadesMap("hex");

  const inputsColorArray: any = ShadeGenerator.hue(
    theme?.input_fields?.["background_color"] || "#fcfcfd",
  ).shadesMap("hex");

  const buttonColorArray: any = ShadeGenerator.hue(
    theme?.buttons?.["background_color"] || "#6270c1",
  ).shadesMap("hex");

  const extendedTheme = extendTheme({
    ...defaultTheme.default,
    palette: {
      ...defaultTheme.default.palette,
      ...(theme
        ? {
            background: {
              default: theme?.layout?.["background_color"] || "#fff",
              paper: theme?.layout?.["background_color"] || "#fff",
            },
            layout: {
              "text-color": theme?.layout?.["text_color"] || "#1b1e3d",
              "border-color": hexRgb(
                theme?.layout?.["text_color"] || "#ebebf0",
                {
                  format: "css",
                  alpha: 0.1,
                },
              ),
              "background-color": theme?.layout?.["background_color"] || "#fff",
              ...Object.keys(layoutTextColorArray).reduce(
                (a: any, c: any) => (
                  (a[`text-color-${c}`] = layoutTextColorArray[c]), a
                ),
                {},
              ),
              ...Object.keys(layoutBgColorArray).reduce(
                (a: any, c: any) => (
                  (a[`background-color-${c}`] = layoutBgColorArray[c]), a
                ),
                {},
              ),
            },
            options: {
              "text-color": theme?.options?.["text_color"] || "#1b1e3d",
              "active-color": theme?.options?.["active_color"] || "#6270c1",
              "default-color": theme?.options?.["default_color"] || "#b4b6c2",
              "hover-default-color":
                theme?.options?.["default_color"] || "#000",
              "active-background-color": hexRgb(
                theme?.options?.["active_color"] || "#ebebf0",
                {
                  format: "css",
                  alpha: 0.1,
                },
              ),
              "hover-background-color": hexRgb(
                theme?.options?.["default_color"] || "#ebebf0",
                {
                  format: "css",
                  alpha: 0.4,
                },
              ),
              "background-color": hexRgb(
                theme?.options?.["default_color"] || "#ffffff",
                {
                  format: "css",
                  alpha: 0.1,
                },
              ),
              "active-border-color": hexRgb(
                theme?.options?.["active_color"] || "#ebebf0",
                {
                  format: "css",
                  alpha: 0.2,
                },
              ),
              "border-color": hexRgb(
                theme?.options?.["default_color"] || "#ebebf0",
                {
                  format: "css",
                  alpha: 0.2,
                },
              ),
              "light-active-background-color": hexRgb(
                theme?.options?.["default_color"] || "#F4F4F8",
                {
                  format: "css",
                  alpha: 0.2,
                },
              ),
              "corners-rounding": theme?.options?.["corners_rounding"] || "6px",
            },
            input_fields: {
              "background-color":
                theme?.input_fields?.["background_color"] || "#fcfcfc",
              "placeholder-text-color": hexRgb(
                theme?.input_fields?.["text_color"] || "#9ea0a5",
                {
                  format: "css",
                  alpha: 0.5,
                },
              ),
              "text-color": theme?.input_fields?.["text_color"] || "#1b1e3d",
              ...Object.keys(inputsColorArray).reduce(
                (a: any, c: any) => (
                  (a[`background-color-${c}`] = inputsColorArray[c]), a
                ),
                {},
              ),
            },
            buttons: {
              "background-color":
                theme?.buttons?.["background_color"] || "#6270c1",
              ...Object.keys(buttonColorArray).reduce(
                (a: any, c: any) => (
                  (a[`background-color-${c}`] = buttonColorArray[c]), a
                ),
                {},
              ),
              "text-color": theme?.buttons?.["text_color"] || "#ffffff",
              "corners-rounding": theme?.buttons?.["corners_rounding"] || "6px",
            },
          }
        : {
            background: {
              default: "#fff",
              paper: "#fff",
            },
            layout: {
              "text-color": "#1b1e3d",
              "border-color": "rgb(27 30 61 / 10%)",
              "background-color": "#fff",
            },
            options: {
              "text-color": "#1b1e3d",
              "active-color": "#6270c1",
              "hover-default-color": "#b4b6c2",
              "default-color": "#b4b6c2",
              "light-active-background-color": "#F7F8F9",
              "active-background-color": "#EFF0F9",
              "hover-background-color": "#E1E2E6",
              "background-color": "#F7F8F9",
              "active-border-color": "rgb(98 112 193 / 20%)",
              "border-color": "rgb(180 182 194 / 50%)",
              "corners-rounding": "6px",
            },
            input_fields: {
              "background-color": "#DFE1E8",
              "text-color": "#1b1e3d",
            },
            buttons: {
              "background-color": "#6270c1",
              "background-color-100": "#6270c1",
              "text-color": "#ffffff",
              "background-color-20": "#e0e2f6",
              "background-color-30": "#cfd4ec",
              "background-color-80": "#7b86db",
              "background-color-200": "#6270c1",
              "corners-rounding": "6px",
            },
          }),
    },
  });

  return (
    <>
      <CssVarsProvider theme={extendedTheme}>
        <CssBaseline />
        <ContentWrapper>{children}</ContentWrapper>
      </CssVarsProvider>
    </>
  );
};
