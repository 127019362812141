import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Currency, ProductType, SelectedProduct } from "..";
import { ImageCarousel } from "../../product/components";
import { Header } from "../../shared";
import { CheckMarkIcon, PlusIconSmall } from "ui";

export const ProductDetailsModal = ({
  isOpen,
  product,
  onClose,
  selected,
  onSelect,
  container,
  currency,
  addToCartLabels,
  enableAddToCartButton = false,
}: {
  isOpen: boolean;
  product: ProductType;
  selected: boolean;
  container?: HTMLElement | undefined;
  currency?: Currency;
  onClose: () => void;
  onSelect: ({
    product_id,
    selected_quantity,
    ...rest
  }: SelectedProduct) => void;
  addToCartLabels: {
    added: string;
    add_to_cart: string;
  };
  enableAddToCartButton?: boolean;
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100dvh",
    bgcolor: "var(--md-palette-layout-background-color)",
    borderStyle: "none",
    boxShadow: 24,
  };

  const [selected_quantity] = useState<number>(1);

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...(container ? { container } : {})}
    >
      <Box sx={style}>
        <Header backButton onBack={() => onClose()} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: ["space-between", "center"],
            alignItems: "center",
            height: "calc(100dvh - 48px)",
          }}
        >
          <Box
            flexDirection={["column", "column"]}
            width={["100%", "600px"]}
            sx={{
              display: "flex",
              margin: ["0", "0 auto"],
              gap: "24px",
              overflowY: "auto",
            }}
          >
            <Box>
              <ImageCarousel images={product?.images} />
            </Box>
            <Stack spacing={1} px={[2, 0]}>
              <Typography variant="body1" fontSize={20} fontWeight={500}>
                {product?.name}
              </Typography>

              <Box
                sx={{
                  "> p:only-child": {
                    m: 0,
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: product?.description,
                }}
              />

              {typeof product?.price === "number" && (
                <Typography variant="body1" fontSize={20} fontWeight={400}>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: currency?.currency_code,
                  }).format(product?.price)}
                </Typography>
              )}
              {enableAddToCartButton && (
                <ContinueButton
                  sx={{
                    width: "fit-content",
                    mt: "12px!important",
                    display: ["none", "flex"],
                  }}
                  selected={selected}
                  addToCartLabels={addToCartLabels}
                  onClick={() =>
                    onSelect({
                      ...product,
                      selected_quantity,
                    })
                  }
                />
              )}
            </Stack>
          </Box>
          {enableAddToCartButton && (
            <Box
              sx={{
                display: ["flex", "none"],
                alignSelf: "flex-end",
                height: "96px",
                width: "100%",
                boxShadow:
                  "inset 0 1px 0 0 var(--md-palette-layout-border-color)",
                justifyContent: "center",
                alignItems: "center",
                px: 3,
              }}
            >
              <ContinueButton
                sx={{
                  py: 1.5,
                }}
                selected={selected}
                addToCartLabels={addToCartLabels}
                onClick={() =>
                  onSelect({
                    ...product,
                    selected_quantity,
                  })
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const ContinueButton = ({
  onClick,
  selected,
  sx = {},
  addToCartLabels,
}: {
  onClick: () => void;
  selected: boolean;
  sx?: any;
  addToCartLabels: {
    added: string;
    add_to_cart: string;
  };
}) => (
  <Button
    variant="outlined"
    sx={{
      width: "100%",
      py: 1,
      ":MuiButton-startIcon": {
        mr: 0,
      },
      backgroundColor: selected
        ? "var(--md-palette-options-active-background-color)"
        : "var(--md-palette-options-background-color)",
      border: selected
        ? "solid 1px var(--md-palette-options-active-border-color)"
        : "solid 1px var(--md-palette-options-border-color)",
      borderRadius: "var(--md-palette-options-corners-rounding)",
      ":hover": {
        backgroundColor: "var(--md-palette-options-hover-background-color)",
      },
      color: "var(--md-palette-options-text-color)",
      pl: "8px",
      pr: "16px",
      ...sx,
    }}
    startIcon={
      selected ? (
        <CheckMarkIcon color="var(--md-palette-options-active-color)" />
      ) : (
        <PlusIconSmall color="var(--md-palette-options-default-color)" />
      )
    }
    onClick={onClick}
  >
    {selected
      ? addToCartLabels?.added || "Added"
      : addToCartLabels?.add_to_cart || "Add to cart"}
  </Button>
);
