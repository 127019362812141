import { Box, IconButton, Typography } from "@mui/material";
import { ArrowIcon, ShoppingCartIcon } from "ui";

export const Header = ({
  showCart,
  testMode,
  hasProducts,
  backButton,
  onBack,
  onCartClick,
}: {
  showCart?: boolean;
  testMode?: boolean;
  hasProducts?: boolean;
  backButton?: boolean;
  onBack?: () => void;
  onCartClick?: () => void;
}) => {
  return (
    <header>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        data-testid="header-box"
        sx={{
          pl: "4px",
          height: 48,
          width: "100%",
          borderBottom: `1px solid ${testMode ? "#ebc9c9" : "var(--md-palette-layout-border-color)"}`,
          outline: 0,
          backgroundColor: "var(--md-palette-layout-background-color)",
          position: 'relative',
        }}
      >
        <Box display="flex" alignItems="center">
          {/* <ImageIcon />
          <Typography variant="h6" ml="8px" fontSize="14px" fontWeight="500">
            Header
          </Typography> */}
          {backButton && (
            <IconButton onClick={() => onBack?.()}>
              <ArrowIcon color="var(--md-palette-layout-text-color)" />
            </IconButton>
          )}
        </Box>
        {/* <Box
          sx={{
            visibility: { sm: "hidden", md: "hidden" },
          }}
          display="flex"
          alignItems="center"
          height="100%"
          borderLeft="1px solid var(--md-palette-layout-border-color)"
          px="7.5px"
        >
          <VeylinxLogoIcon w={32} h={32} />
        </Box> */}
        {showCart && (
          <Box
            px="11.5px"
            height="100%"
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            borderLeft="1px solid var(--md-palette-layout-border-color)"
            onClick={onCartClick}
          >
            {hasProducts && (
              <Box
                sx={{
                  position: "absolute",
                  top: "13px",
                  right: "11px",
                  width: "10px",
                  height: "10px",
                  borderRadius: "7px",
                  backgroundImage:
                    "linear-gradient(to bottom, #d94747, #c41e1e 100%)",
                }}
              />
            )}

            <ShoppingCartIcon color="var(--md-palette-layout-text-color)" />
          </Box>
        )}
        {testMode && (
          <Box
            sx={{
              width: "75px",
              height: "21px",
              marginBottom: "-70px",
              padding: "4px 8px 5px",
              borderRadius: "0 0 8px 8px",
              borderLeft: "1px solid #e7c0c0",
              borderBottom: "1px solid #e7c0c0",
              borderRight: "1px solid #e7c0c0",
              backgroundColor: "#feeeee",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                height: "12px",
                fontSize: "9px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#cc5b5b",
                textTransform: "uppercase",
              }}
              variant="body2"
            >
              test mode
            </Typography>
          </Box>
        )}
      </Box>
    </header>
  );
};
