import {
  Box,
  BoxProps,
  ClickAwayListener,
  ListItemIcon,
  Backdrop as MuiBackdrop,
  Popper as MuiPopper,
  PopperPlacementType,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { uniqueId } from "lodash";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { MenuCloseIcon, MenuIcon, SelectArrowIcon, SelectClearIcon } from "ui";

// This could be used in to build a component like a select | dropdown | menu...
export const FloatingLayer = ({
  reference,
  trigger = "click",
  placement = "bottom-start",
  closeOnChildClick,
  ignoreReferenceInteraction,
  fullWidth = false,
  positionOffset,
  disabled,
  children,
  popperStyles,
  referenceWrapperStyles,
  onOpen,
  onClose,
  backdrop,
  isOpen,
  onClick,
  debugKey,
  blockParentOnClick,
  disablePortal = true,
}: PopperProps) => {
  const [open, setIsOpen] = useState<boolean>(false);
  const mainRef = useRef(null);
  const backdropRef = useRef(null);
  const childrenRef = useRef(null);
  const [popperRef, setPopperRef] = useState<any>(null);
  const handleOpen = (value: (EventTarget & HTMLDivElement) | null) => {
    setIsOpen(!!value);
    value ? onOpen?.() : onClose?.();
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
    // check first if e.relatedTarget an element:
    if (e.relatedTarget instanceof Element) {
      const isLeaving =
        !(mainRef.current as any)?.contains(e.relatedTarget) &&
        !(popperRef as any)?.contains(e.relatedTarget);

      //   const isLeaving2 =
      //     !(mainRef.current as any)?.contains(e.relatedTarget) &&
      //     !(popperRef as any)?.contains(e.relatedTarget);

      if (isLeaving && trigger === "hover") {
        handleClose();
      }
    }
  };

  const handlePopperMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
    if (trigger === "hover") {
      const mainElement = mainRef.current as HTMLElement | null;
      const relatedTarget = e.relatedTarget;

      if (
        mainElement &&
        relatedTarget instanceof Node &&
        !mainElement.contains(relatedTarget)
      ) {
        handleClose();
      }
    }
  };

  const handleReferenceClick = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    onClick?.();
    const shouldClose =
      !ignoreReferenceInteraction || (ignoreReferenceInteraction && !open);
    if (trigger !== "click" || !shouldClose) return;
    if (blockParentOnClick) event?.stopPropagation();

    return setIsOpen((pv) => {
      if (!pv) onOpen?.();
      return !pv;
    });
  };

  const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
    const hasChildrenWithTooltip = (
      childrenRef.current as HTMLElement | null
    )?.querySelector('[role="tooltip"]');

    const target = e.target as HTMLElement;
    const isBackdropClicked =
      target &&
      typeof target.className === "string" &&
      target.className.indexOf("menu_backdrop") !== -1;

    if (
      !hasChildrenWithTooltip &&
      (!ignoreReferenceInteraction || isBackdropClicked)
    ) {
      handleClose();
    }
  };

  const handlePopperContentClick = () => {
    const hasChildrenWithTooltip = (childrenRef.current as any)?.querySelector(
      '[role="tooltip"]',
    );

    if (!hasChildrenWithTooltip && closeOnChildClick) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleReferenceClick();
    } else {
      handleClose();
    }
  }, [isOpen]);

  const id = uniqueId();
  const popperId = open ? id : undefined;
  const placementType = popperRef?.getAttribute("data-popper-placement");

  return (
    <>
      <Box
        className={`popper-main ${debugKey}`}
        ref={mainRef}
        aria-describedby={popperId}
        onClick={handleReferenceClick}
        onMouseEnter={(e) => trigger === "hover" && handleOpen(e.currentTarget)}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: "relative",
          zIndex: 13,
          ...(open && !disabled && { zIndex: 10002 }),
          ...(disabled && { opacity: 0.7 }),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          userSelect: "none",
          "> *": { width: "100%", userSelect: "none" },
          ...referenceWrapperStyles?.(placementType),
        }}
      >
        {reference(open)}
      </Box>

      <MuiPopper
        id={popperId}
        open={open}
        anchorEl={mainRef?.current}
        placement={placement}
        onPointerEnterCapture={() => null}
        onPointerLeaveCapture={() => null}
        placeholder={false}
        ref={setPopperRef}
        onMouseLeave={handlePopperMouseLeave}
        sx={{
          zIndex: 10001,
          ...popperStyles?.(placementType),
          ...getPositionStyles(positionOffset?.(placementType)),
        }}
        disablePortal={disablePortal}
      >
        <ClickAwayListener onClickAway={handleOutsideClick}>
          <Box
            ref={childrenRef}
            className="popper-container"
            onMouseLeave={handleMouseLeave}
            onClick={handlePopperContentClick}
            sx={{
              width: fullWidth
                ? (mainRef?.current as any)?.clientWidth
                : "auto",
            }}
          >
            {children}
          </Box>
        </ClickAwayListener>
      </MuiPopper>

      <Backdrop
        {...backdrop}
        backdropRef={backdropRef}
        visible={open && !backdrop?.hideBackdrop}
      />
    </>
  );
};

const getPositionStyles = (
  props:
    | { right?: number; left?: number; top?: number; bottom?: number }
    | undefined,
) => {
  const { right, left, top, bottom } = props || {};

  return {
    ...(right && { paddingRight: right?.toString() + "px !important" }),
    ...(left && { paddingLeft: left?.toString() + "px !important" }),
    ...(top && { paddingTop: top?.toString() + "px !important" }),
    ...(bottom && { paddingBottom: bottom?.toString() + "px !important" }),
  };
};

const Backdrop = ({
  visible,
  backdropStyles,
  onClick,
  backdropRef,
}: {
  visible: boolean;
  onClick?: () => void;
  backdropStyles?: SxProps<Theme>;
  backdropRef?: React.Ref<HTMLDivElement>;
}) => {
  if (!visible) return null;

  return (
    <MuiBackdrop
      className="menu_backdrop"
      ref={backdropRef}
      open={visible}
      sx={{
        margin: "0 !important",
        zIndex: 10000,
        backgroundColor: "rgba(41, 44, 87, 0.2)",
        ":hover": { backgroundColor: "rgba(41, 44, 87, 0.2) !important" },
        ...backdropStyles,
      }}
      onClick={() => onClick?.()}
    />
  );
};

export const MenuOpen = (props: BoxProps) => {
  return (
    <Box
      className="menu-icon menu-open"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "24px",
        height: "24px",
        cursor: "pointer",
        borderRadius: "50%",
        background: "linear-gradient(180deg,#5b6379,#464c5e)",
      }}
      {...props}
    >
      <MenuCloseIcon color="white" />
    </Box>
  );
};
export const MenuClose = (props: BoxProps) => {
  return (
    <Box
      className="menu-icon menu-close"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "24px",
        height: "24px",
        cursor: "pointer",
        ":hover": {
          background: "rgba(0, 0, 0, 0.04)",
          borderRadius: "50%",
        },
      }}
      {...props}
    >
      <MenuIcon />
    </Box>
  );
};

export const MenuItem = ({
  icon,
  label,
  disabled,
  action,
}: {
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
  action: () => void;
}) => {
  return (
    <Box
      className="menu-item"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        minWidth: "172px",
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        ":hover": {
          backgroundImage: " linear-gradient(to bottom, #f8f8fa 0%, #ebebf3)",
          borderRadius: "6px",
        },
      }}
      onClick={() => !disabled && action()}
    >
      <Box
        sx={{
          width: "24px",
          height: "24px",
          minWidth: "24px",
          borderRadius: "3px",
          marginRight: "8px",
          backgroundImage: "linear-gradient(to bottom, #f8f8fa 0%, #ebebf3)",
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
      </Box>
      <Typography>{label}</Typography>
    </Box>
  );
};

export const SelectField = ({
  open,
  value,
  placeholder,
  fieldStyles,
  icon,
  clearable,
  onClear,
  onClick,
}: SelectFieldProps) => {
  const styles = value
    ? { color: "var(--md-palette-input_fields-text-color)" }
    : { color: "var(--md-palette-input_fields-placeholder-text-color)" };
  return (
    <>
      <Box
        sx={{
          px: "8px",
          height: "38px",
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          backgroundColor: "#fff",
          border: "solid 1px #ebebf0",
          justifyContent: "space-between",
          userSelect: "none",
          position: "relative",
          borderRadius: open ? "6px 6px 0px 0px" : "6px",
          ...fieldStyles,
        }}
        className="select-field"
        onClick={onClick}
      >
        {typeof value === "string" || !value ? (
          <Typography
            className="select-field-value"
            sx={{
              fontSize: "14px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...styles,
            }}
          >
            {value || placeholder}
          </Typography>
        ) : (
          value
        )}
        {((clearable && !value) || !clearable) &&
          (icon || (
            <Box
              className="select-field-icon"
              sx={{
                ml: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "24px",
                transform: `rotate(${open ? 180 : 0}deg)`,
              }}
            >
              <SelectArrowIcon open={open} w="24" />
            </Box>
          ))}
        {clearable && value && (
          <Box
            className="select-field-clear"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClear?.();
            }}
          >
            <SelectClearIcon />
          </Box>
        )}
      </Box>
    </>
  );
};

export const menuCommonStyles = {
  trigger: "click",
  closeOnChildClick: true,
  blockParentOnClick: true,
  placement: "bottom-end",
  popperStyles: (placement: any) => ({
    padding: "8px",
    borderRadius: "3px",
    background: "white",
    ...(placement === "bottom-end"
      ? { mt: "-11px !important", mr: "10px !important" }
      : { mb: "-11px !important", mr: "10px !important" }),
  }),
  reference: (open: boolean) => (open ? <MenuOpen /> : <MenuClose />),
} as Omit<PopperProps, "children">;

export interface PopperProps {
  id?: string;
  reference: (open: boolean) => React.ReactNode;
  trigger?: "click" | "hover";
  placement?: PopperPlacementType;
  blockParentOnClick?: boolean;
  closeOnChildClick?: boolean;
  ignoreReferenceInteraction?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
  disablePortal?: boolean;
  popperStyles?: (placement: PopperPlacementType) => SxProps<Theme>;
  referenceWrapperStyles?: (placement: PopperPlacementType) => SxProps<Theme>;
  onOpen?: () => void;
  onClose?: () => void;
  onClick?: () => void;
  positionOffset?: (placement: PopperPlacementType) => {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
  backdrop?: {
    visible?: boolean;
    onClick?: () => void;
    backdropStyles?: SxProps<Theme>;
    backdropCustomStyles?: SxProps<Theme>;
    backdropCustom?: boolean;
    hideBackdrop?: boolean;
  };
  isOpen?: boolean;
  debugKey?: number | string;
}

type SelectFieldProps = {
  open?: boolean;
  icon?: ReactElement;
  onClick?: () => void;
  fieldStyles?: SxProps<Theme>;
  value?: string | ReactNode | ReactElement | null;
  placeholder?: string;
  clearable?: boolean;
  onClear?: () => void;
};

// Styles:
export const dropdownCommonConfig = {
  trigger: "click",
  backdrop: { hideBackdrop: false },
  placement: "bottom-start",
  referenceWrapperStyles: () => ({ width: "100%" }),
  fullWidth: true,
  disablePortal: false,
  popperStyles: () => ({
    ".popper-container": {
      background: "white",
      borderRadius: "0px 0px 6px 6px",
      padding: "8px",
      boxSizing: "border-box",
      //   minWidth: "188px", // test the market potential categories items
    },
  }),
} as Partial<PopperProps>;

// const HowToUse = () => {
//   return (
//     <FloatingLayer
//       debugKey="parent"
//       trigger="click"
//       placement="bottom"
//       reference={(open) => (
//         <Box sx={{ background: "red" }}>
//           {open ? <Box>Close Menu</Box> : <Box>Open Menu</Box>}
//         </Box>
//       )}
//     >
//       <Box sx={{ background: "white", p: 6 }}>
//         <FloatingLayer
//           closeOnChildClick
//           ignoreReferenceInteraction
//           debugKey="child"
//           placement="bottom"
//           trigger="click"
//           reference={(open) => <Box>Child Reference</Box>}
//         >
//           <Box sx={{ background: "white", p: 6 }}>
//             {/* Children content here */}
//           </Box>
//         </FloatingLayer>
//       </Box>
//     </FloatingLayer>
//   );
// };
