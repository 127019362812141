"use client";
import { useAppContext } from "@/src/contexts";
import { EmptyLoader } from "@src/components/Loader";
import { PropsWithChildren, Suspense } from "react";
import { Footer, Header } from "steps";

export const ContentWrapper = ({ children }: PropsWithChildren) => {
  const { hasProducts, showCart, setShoppingCartOpen, testMode } =
    useAppContext();

  return (
    <>
      <Header
        showCart={showCart}
        testMode={testMode}
        hasProducts={hasProducts}
        onCartClick={() => setShoppingCartOpen((p) => !p)}
      />
      <Suspense fallback={<EmptyLoader />}>{children}</Suspense>
      <Footer />
    </>
  );
};
